import { Container } from '@mantine/core';
import { UsersRolesTable } from './UsersRolesTable';

export function UsersRoles() {
    return (
        <Container size="lg">
            <UsersRolesTable />
        </Container>
    );
}
