import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { LoadingOverlay } from '@mantine/core';
import { ClaimApproval } from '@/containers/expense/ClaimApproval/ClaimApproval';
import { ClaimExport } from '@/containers/expense/ClaimExport/ClaimExport';
import { ClaimTable } from '@/containers/expense/ClaimTable/ClaimTable';
import { InvoiceUpload } from '@/containers/expense/InvoiceUpload/InvoiceUpload';
import { UsersRoles } from '@/containers/organization/UsersRoles/UsersRoles';
import { AnalyticsPlayground } from '@/containers/playground/AnalyticsPlayground/AnalyticsPlayground';
import { CustomDataTable } from '@/containers/playground/CustomDataTable/CustomDataTable';
import { InvoiceUpload as PlaygroundInvoiceUpload } from '@/containers/playground/InvoiceUpload/InvoiceUpload';
import { ProductAssistant } from '@/containers/product/ProductAssistant/ProductAssistant';
import HomePage from '@/pages/Home.page';
import WelcomePage from '@/pages/Welcome.page';
import { useAuth } from '@/providers/auth/AuthProvider';
import { NothingFoundBackground } from '../components/NothingFoundBackground/NothingFoundBackground';
import DevRoute from './DevRoute';
import ProtectedRoute from './ProtectedRoute';

const router = createBrowserRouter(
    [
        {
            path: '/welcome',
            element: <WelcomePage />,
        },
        {
            path: '/',
            element: <ProtectedRoute />,
            children: [
                {
                    path: '',
                    element: <HomePage />,
                    children: [
                        {
                            path: '*',
                            element: <NothingFoundBackground />,
                        },
                        {
                            path: '',
                            element: <InvoiceUpload />,
                        },
                        {
                            path: 'expense/upload',
                            element: <InvoiceUpload />,
                        },
                        {
                            path: 'expense/claim',
                            element: <ClaimTable />,
                        },
                        {
                            path: 'expense/approval',
                            element: <ClaimApproval />,
                        },
                        {
                            path: 'expense/export',
                            element: <ClaimExport />,
                        },
                        {
                            path: 'product/assistant',
                            element: <ProductAssistant />,
                        },
                        {
                            path: 'organization/users',
                            element: <UsersRoles />,
                        },
                        {
                            path: 'playground/*',
                            element: <DevRoute />,
                            children: [
                                {
                                    path: '*',
                                    element: <NothingFoundBackground />,
                                },
                                {
                                    path: 'invoice',
                                    element: <PlaygroundInvoiceUpload />,
                                },
                                {
                                    path: 'datatable',
                                    element: <CustomDataTable />,
                                },
                                {
                                    path: 'analytics',
                                    element: <AnalyticsPlayground />,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
    {
        future: {
            v7_relativeSplatPath: true,
            v7_fetcherPersist: true,
            v7_normalizeFormMethod: true,
            v7_partialHydration: true,
            v7_skipActionErrorRevalidation: true,
        },
    }
);

export function Router() {
    const { isAuthenticated } = useAuth();

    if (isAuthenticated === null) {
        return <LoadingOverlay visible zIndex={1000} overlayProps={{ radius: 'sm', blur: 2 }} />;
    }

    return (
        <RouterProvider
            router={router}
            future={{
                v7_startTransition: true,
            }}
        />
    );
}
