import { Badge } from '@mantine/core';
import { Role } from '@/types/role';

const roleColors: Record<string, string> = {
    [Role.Admin]: 'red',
    [Role.Dev]: 'green',
    [Role.OrgAdmin]: 'violet',
    [Role.OrgUser]: 'blue',
};

export const RoleBadge = ({ role }: { role: string }) => {
    return (
        <Badge color={roleColors[role] || 'gray'} variant="light">
            {role}
        </Badge>
    );
};
