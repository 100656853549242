import { useCallback, useEffect, useState } from 'react';
import { IconPencil } from '@tabler/icons-react';
import { formatDistanceToNow } from 'date-fns/formatDistanceToNow';
import { ActionIcon, Avatar, Badge, Flex, Group, rem, Skeleton, Table, Text } from '@mantine/core';
import { Configuration, UserApi } from '@/api';
import { RoleBadge } from '@/components/RoleBadge/RoleBadge';
import { capture } from '@/handlers/error';
import { useAuth } from '@/providers/auth/AuthProvider';

interface Record {
    uid?: string;
    email?: string;
    displayName?: string;
    photoURL?: string;
    disabled?: boolean;
    oid?: string;
    lastActive?: string | null;
    roles?: string[];
    lineManager?: Record;
}

export function UserRolesSkeleton() {
    return (
        <Table.Tbody>
            {Array.from({ length: 10 }).map((_, index) => (
                <Table.Tr key={`skeleton-${index}`}>
                    <Table.Td>
                        <Skeleton height={40} width="100%" />
                    </Table.Td>
                    <Table.Td>
                        <Skeleton height={40} width="100%" />
                    </Table.Td>
                    <Table.Td>
                        <Skeleton height={40} width="100%" />
                    </Table.Td>
                    <Table.Td>
                        <Skeleton height={40} width="100%" />
                    </Table.Td>
                    <Table.Td>
                        <Skeleton height={40} width="100%" />
                    </Table.Td>
                    <Table.Td />
                </Table.Tr>
            ))}
        </Table.Tbody>
    );
}

export function UsersRolesTable() {
    const [records, setRecords] = useState<Record[]>([]);
    const [loading, setLoading] = useState(false);
    const { currentUser } = useAuth();

    const refresh = useCallback(async () => {
        setLoading(true);

        try {
            const token = await currentUser?.getIdToken();
            if (!token) {
                return;
            }
            const api = new UserApi(new Configuration({ accessToken: token }));
            const res = await api.usersGet();
            setRecords(res.data);
        } catch (error) {
            capture(error);
        } finally {
            setLoading(false);
        }
    }, [currentUser]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const rows = records.map((item) => (
        <Table.Tr key={item.uid}>
            <Table.Td>
                <Group gap="sm">
                    <Avatar size={40} src={item.photoURL} radius={40} />
                    <div>
                        <Text fz="sm" fw={500}>
                            {item.displayName}
                        </Text>
                        <Text fz="xs" c="dimmed">
                            {item.email}
                        </Text>
                    </div>
                </Group>
            </Table.Td>
            <Table.Td>
                <Flex columnGap={rem(4)} rowGap={rem(6)} wrap="wrap">
                    {item.roles?.map((role) => {
                        return <RoleBadge key={role} role={role} />;
                    })}
                </Flex>
            </Table.Td>
            <Table.Td>
                {item.lineManager && (
                    <Group gap="sm">
                        <Avatar size={40} src={item.lineManager.photoURL} radius={40} />
                        <div>
                            <Text fz="sm" fw={500}>
                                {item.lineManager.displayName}
                            </Text>
                            <Text fz="xs" c="dimmed">
                                {item.lineManager.email}
                            </Text>
                        </div>
                    </Group>
                )}
            </Table.Td>
            <Table.Td>{item.lastActive ? `${formatDistanceToNow(item.lastActive)} ago` : ''}</Table.Td>
            <Table.Td>
                {!item.disabled ? (
                    <Badge fullWidth variant="light">
                        Active
                    </Badge>
                ) : (
                    <Badge color="gray" fullWidth variant="light">
                        Disabled
                    </Badge>
                )}
            </Table.Td>
            <Table.Td>
                <Group gap={0} justify="flex-end">
                    <ActionIcon variant="subtle" color="gray">
                        <IconPencil size={16} stroke={1.5} />
                    </ActionIcon>
                </Group>
            </Table.Td>
        </Table.Tr>
    ));

    return (
        <Table.ScrollContainer minWidth={1100}>
            <Table verticalSpacing="sm">
                <Table.Thead>
                    <Table.Tr>
                        <Table.Th>Employee</Table.Th>
                        <Table.Th>Role</Table.Th>
                        <Table.Th>Line Manager</Table.Th>
                        <Table.Th>Last Active</Table.Th>
                        <Table.Th>Status</Table.Th>
                        <Table.Th />
                    </Table.Tr>
                </Table.Thead>
                {loading ? <UserRolesSkeleton /> : <Table.Tbody>{rows}</Table.Tbody>}
            </Table>
        </Table.ScrollContainer>
    );
}
