import { useCallback, useEffect, useRef, useState } from 'react';
import {
    IconCheck,
    IconChevronRight,
    IconClick,
    IconExclamationCircle,
    IconExclamationCircleFilled,
    IconEye,
    IconLayoutBottombarCollapse,
    IconLayoutBottombarCollapseFilled,
    IconSearch,
    IconShare2,
    IconX,
} from '@tabler/icons-react';
import clsx from 'clsx';
import { collection, endAt, getDocs, limit, orderBy, query, QueryConstraint, QueryDocumentSnapshot, startAfter, where } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { DataTable } from 'mantine-datatable';
import Markdown from 'react-markdown';
import { ActionIcon, Anchor, Box, Breadcrumbs, Button, Center, Group, Image, Modal, rem, Select, Stack, Text, TextInput, Title, useModalsStack } from '@mantine/core';
import { DatePicker, type DatesRangeValue } from '@mantine/dates';
import { useDebouncedValue, useDidUpdate, useMediaQuery } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { CountingButton } from '@/components/CountingButton/CountingButton';
import { MissingIcon } from '@/components/MissingIcon/MissingIcon';
import { db, Firebase, storage } from '@/firebase';
import { capture } from '@/handlers/error';
import { useAuth } from '@/providers/auth/AuthProvider';
import { Record } from '@/types/claim';
import { currencyMap } from '@/types/currency';
import { ExportOption, isExportOption } from '@/types/export';
import { recordExport } from './export';
import classes from './ClaimExport.module.css';

const PAGE_SIZE = 50;

function IconInvalid({ filled, size = 20 }: { filled?: boolean; size?: number }) {
    return (
        <Box c="yellow.4">
            <Center>{filled ? <IconExclamationCircleFilled size={size} /> : <IconExclamationCircle size={size} />}</Center>
        </Box>
    );
}

export function ClaimExport() {
    const { currentUser } = useAuth();

    // Generic state
    const [org, setOrg] = useState<string | null>(null);
    const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot | undefined>(undefined);
    const isMobile = useMediaQuery('(max-width: 50em)');

    // DataTable state
    const [loading, setLoading] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [records, setRecords] = useState<Record[] | undefined>(undefined);
    const scrollViewportRef = useRef<HTMLDivElement>(null);
    const [selectedRecords, setSelectedRecords] = useState<Record[]>([]);
    const [expandedRecordIds, setExpandedRecordIds] = useState<string[]>([]);

    // Modal state
    const stack = useModalsStack(['preview-file', 'export-selection']);
    const [previewRecord, setPreviewRecord] = useState<Record | null>(null);
    const [exportOption, setExportOption] = useState<string | null>(ExportOption.SAP);

    // Filter state
    const [requesterQueryConstraint, setRequesterQueryConstraint] = useState<QueryConstraint[]>([]);
    const [requesterFilter, setRequesterFilter] = useState('');
    const [debouncedRequesterFilter] = useDebouncedValue(requesterFilter, 400);
    const [creationQueryConstraint, setCreationQueryConstraint] = useState<QueryConstraint[]>([]);
    const [creationDateFilterRange, setCreationDateFilterRange] = useState<DatesRangeValue>();
    const [submissionQueryConstraint, setSubmissionQueryConstraint] = useState<QueryConstraint[]>([]);
    const [submissionDateFilterRange, setSubmissionDateFilterRange] = useState<DatesRangeValue>();

    const setPreview = async (record: Record) => {
        try {
            if (record.url) {
                await getDownloadURL(ref(storage, record.url)).then((url) => {
                    setPreviewRecord({ ...record, url });
                    stack.open('preview-file');
                });
            }
        } catch (error) {
            capture(error);
        }
    };

    const loadMoreRecords = useCallback(
        (lastVisible?: QueryDocumentSnapshot) => {
            if (!org) {
                return;
            }

            setLoading(true);

            const q = query(
                collection(db, Firebase.Firestore.Collection.Claims),
                orderBy('submission.timestamp', 'desc'),
                where('submission.oid', '==', org),
                ...requesterQueryConstraint,
                ...creationQueryConstraint,
                ...submissionQueryConstraint,
                ...(lastVisible ? [startAfter(lastVisible)] : []),
                limit(PAGE_SIZE)
            );

            getDocs(q)
                .then((snapshot) => {
                    const result = snapshot.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        };
                    });
                    setRecords((r) => (r ? r.concat(result) : result));
                    setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [org, requesterQueryConstraint, creationQueryConstraint, submissionQueryConstraint]
    );

    const refresh = useCallback(
        async (lastVisible?: QueryDocumentSnapshot) => {
            if (!org) {
                return;
            }

            setLoading(true);

            const q = query(
                collection(db, Firebase.Firestore.Collection.Claims),
                orderBy('submission.timestamp', 'desc'),
                where('submission.oid', '==', org),
                ...requesterQueryConstraint,
                ...creationQueryConstraint,
                ...submissionQueryConstraint,
                lastVisible ? endAt(lastVisible) : limit(PAGE_SIZE)
            );

            await getDocs(q)
                .then((snapshot) => {
                    const result = snapshot.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        };
                    });
                    setRecords(result);
                    setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
                })
                .catch((error) => capture(error))
                .finally(() => {
                    setLoading(false);
                });
        },
        [org, requesterQueryConstraint, creationQueryConstraint, submissionQueryConstraint]
    );

    const exportRecord = useCallback(
        async (records: Record[], option: ExportOption) => {
            if (option === ExportOption.CSV) {
                notifications.show({ message: 'Coming soon!!!' });
                return;
            }

            if (currentUser) {
                setExporting(true);
                const nid = notifications.show({
                    loading: true,
                    title: 'Exporting record',
                    message: `Exporting ${records.length} records(s) to ${option}`,
                    autoClose: false,
                    withCloseButton: false,
                });
                const token = await currentUser.getIdToken();
                await recordExport(records, token)
                    .then(() => {
                        notifications.update({
                            id: nid,
                            color: 'green.4',
                            title: 'Success',
                            message: `${records.length} record(s) is exported`,
                            icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
                            loading: false,
                            autoClose: 4000,
                        });
                    })
                    .catch((error) => {
                        capture(error, {
                            id: nid,
                            color: 'red.4',
                            icon: <IconX style={{ width: rem(18), height: rem(18) }} />,
                            loading: false,
                            autoClose: 4000,
                            classNames: undefined,
                        });
                    })
                    .finally(() => {
                        setExporting(false);
                    });
            }
        },
        [currentUser]
    );

    useEffect(() => {
        if (currentUser) {
            currentUser.getIdTokenResult().then((idTokenResult) => {
                if (idTokenResult.claims.org && typeof idTokenResult.claims.org === 'string') {
                    setOrg(idTokenResult.claims.org);
                }
            });
        }
    }, [currentUser]);

    useEffect(() => {
        setRecords(undefined);
    }, []);

    useEffect(() => {
        refresh();
    }, [refresh]);

    // Requetser filter
    useDidUpdate(() => {
        const constraint = [];

        if (debouncedRequesterFilter) {
            constraint.push(where('submission.email', '>=', debouncedRequesterFilter));
            constraint.push(where('submission.email', '<', `${debouncedRequesterFilter}\uf8ff`));
        }

        setRequesterQueryConstraint(constraint);
    }, [debouncedRequesterFilter]);

    // Creation date filter
    useDidUpdate(() => {
        if (!creationDateFilterRange) {
            setCreationQueryConstraint([]);
            return;
        }

        const constraint = [];

        if (creationDateFilterRange && creationDateFilterRange[0] && creationDateFilterRange[1]) {
            const start = creationDateFilterRange[0];
            const end = creationDateFilterRange[1];
            end.setDate(end.getDate() + 1);
            constraint.push(where('creation', '>=', start));
            constraint.push(where('creation', '<', end));
            setCreationQueryConstraint(constraint);
        }
    }, [creationDateFilterRange]);

    // Submission date filter
    useDidUpdate(() => {
        if (!submissionDateFilterRange) {
            setSubmissionQueryConstraint([]);
            return;
        }

        const constraint = [];

        if (submissionDateFilterRange && submissionDateFilterRange[0] && submissionDateFilterRange[1]) {
            const start = submissionDateFilterRange[0];
            const end = submissionDateFilterRange[1];
            end.setDate(end.getDate() + 1);
            constraint.push(where('submission.timestamp', '>=', start));
            constraint.push(where('submission.timestamp', '<', end));
            setSubmissionQueryConstraint(constraint);
        }
    }, [submissionDateFilterRange]);

    return (
        <Box display="flex" style={{ width: '100%', flexDirection: 'column' }}>
            <Box mb="sm" display="flex" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <Breadcrumbs>
                    {[{ title: 'Expense' }, { title: 'Export' }].map((item, index) => (
                        <Title order={3} key={index}>
                            {item.title}
                        </Title>
                    ))}
                </Breadcrumbs>
                <Group justify="end">
                    <CountingButton
                        onClick={() => stack.open('export-selection')}
                        disabled={selectedRecords.length <= 0}
                        loading={exporting}
                        leftSection={`${selectedRecords.length}`}
                        rightSection={<IconShare2 style={{ width: rem(18) }} />}
                    >
                        Export
                    </CountingButton>
                </Group>
            </Box>
            <Box style={{ position: 'relative', flex: 1, backgroundColor: 'yellow' }}>
                <Box style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                    <DataTable
                        withTableBorder={false}
                        striped
                        classNames={{
                            header: classes.header,
                        }}
                        highlightOnHover
                        columns={[
                            {
                                accessor: 'submission.analysis',
                                width: rem(32),
                                title: (
                                    <Center>
                                        <ActionIcon
                                            size={20}
                                            variant="outline"
                                            color="secondary"
                                            onClick={() => {
                                                records && expandedRecordIds.length < records?.length ? setExpandedRecordIds(records.map((record) => record.id)) : setExpandedRecordIds([]);
                                            }}
                                        >
                                            {records && expandedRecordIds.length < records?.length ? <IconLayoutBottombarCollapse /> : <IconLayoutBottombarCollapseFilled />}
                                        </ActionIcon>
                                    </Center>
                                ),
                                titleClassName: classes.expandColumnCells,
                                cellsClassName: classes.expandColumnCells,
                                textAlign: 'center',
                                render: (record) => {
                                    return record.submission?.analysis ? (
                                        <IconChevronRight
                                            stroke={1.5}
                                            className={clsx(classes.icon, classes.expandIcon, {
                                                [classes.expandIconRotated]: expandedRecordIds.includes(record.id),
                                            })}
                                        />
                                    ) : null;
                                },
                            },
                            {
                                accessor: 'submission.valid',
                                title: '',
                                cellsStyle: () => ({ padding: 0 }),
                                render: (record) => (record.submission?.valid === false ? <IconInvalid /> : null),
                            },
                            {
                                accessor: 'name',
                                render: (record) => {
                                    if (record.url) {
                                        return (
                                            <Anchor
                                                fz="sm"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setPreview(record);
                                                }}
                                            >
                                                {record.name}
                                            </Anchor>
                                        );
                                    }
                                    return record.name;
                                },
                            },
                            {
                                accessor: 'summary',
                                render: (record) => {
                                    return record.description || <MissingIcon />;
                                },
                            },
                            {
                                accessor: 'category',
                            },
                            {
                                accessor: 'currencyCode',
                                title: 'Currency',
                                render: ({ currencyCode }) => {
                                    const value = currencyCode && currencyMap[currencyCode] ? `${currencyMap[currencyCode]} ${currencyCode}` : currencyCode;

                                    return value || <MissingIcon />;
                                },
                            },
                            {
                                accessor: 'amount',
                                render: ({ amount }) =>
                                    amount ? (
                                        <Text c="teal" size="sm">
                                            {amount}
                                        </Text>
                                    ) : (
                                        <MissingIcon />
                                    ),
                            },
                            {
                                accessor: 'date',
                                noWrap: true,
                                render: ({ date }) => date || <MissingIcon />,
                            },
                            {
                                accessor: 'submission.email',
                                title: 'Requester',
                                noWrap: true,
                                filter: (
                                    <TextInput
                                        label="Employees"
                                        description="Show employees whose names include the specified text"
                                        placeholder="Search employees..."
                                        leftSection={<IconSearch size={16} />}
                                        rightSection={
                                            <ActionIcon size="sm" variant="transparent" c="dimmed" onClick={() => setRequesterFilter('')}>
                                                <IconX size={14} />
                                            </ActionIcon>
                                        }
                                        value={requesterFilter}
                                        onChange={(e) => setRequesterFilter(e.currentTarget.value)}
                                    />
                                ),
                                filtering: requesterFilter !== '',
                            },
                            {
                                accessor: 'creation',
                                render: ({ creation }) => `${creation?.toDate().toLocaleString()}`,
                                noWrap: true,
                                filter: ({ close }) => (
                                    <Stack>
                                        <DatePicker maxDate={new Date()} type="range" value={creationDateFilterRange} onChange={setCreationDateFilterRange} />
                                        <Button
                                            disabled={!creationDateFilterRange}
                                            variant="light"
                                            onClick={() => {
                                                setCreationDateFilterRange(undefined);
                                                close();
                                            }}
                                        >
                                            Clear
                                        </Button>
                                    </Stack>
                                ),
                                filtering: Boolean(creationDateFilterRange),
                            },
                            {
                                accessor: 'submission.timestamp',
                                title: 'Submission',
                                render: (record) => (record.submission?.timestamp ? `${record?.submission?.timestamp?.toDate().toLocaleString()}` : 'Pending'),
                                noWrap: true,
                                filter: ({ close }) => (
                                    <Stack>
                                        <DatePicker maxDate={new Date()} type="range" value={submissionDateFilterRange} onChange={setSubmissionDateFilterRange} />
                                        <Button
                                            disabled={!submissionDateFilterRange}
                                            variant="light"
                                            onClick={() => {
                                                setSubmissionDateFilterRange(undefined);
                                                close();
                                            }}
                                        >
                                            Clear
                                        </Button>
                                    </Stack>
                                ),
                                filtering: Boolean(submissionDateFilterRange),
                            },
                            {
                                accessor: 'actions',
                                title: (
                                    <Center>
                                        <IconClick size={20} />
                                    </Center>
                                ),
                                textAlign: 'right',
                                render: (record) => (
                                    <Group gap={4} justify="right" wrap="nowrap">
                                        <ActionIcon
                                            size="sm"
                                            variant="subtle"
                                            color="green"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setPreview(record);
                                            }}
                                            disabled={!record.url}
                                        >
                                            <IconEye size={16} />
                                        </ActionIcon>
                                    </Group>
                                ),
                            },
                        ]}
                        pinFirstColumn
                        pinLastColumn
                        records={records}
                        fetching={loading}
                        rowExpansion={{
                            allowMultiple: true,
                            expanded: { recordIds: expandedRecordIds, onRecordIdsChange: setExpandedRecordIds },
                            expandable: ({ record: { submission } }) => !!submission?.analysis,
                            content: ({ record }) => (
                                <Box pl={rem(72)} pr="xs">
                                    <Group gap="xs" wrap="nowrap">
                                        {record.submission?.valid === false && <IconInvalid filled />}
                                        <Markdown>{record.submission?.analysis}</Markdown>
                                    </Group>
                                </Box>
                            ),
                        }}
                        selectedRecords={selectedRecords}
                        onSelectedRecordsChange={setSelectedRecords}
                        selectionTrigger="cell"
                        onScrollToBottom={() => {
                            if (records && lastVisible && org) {
                                loadMoreRecords(lastVisible);
                            }
                        }}
                        scrollViewportRef={scrollViewportRef}
                    />
                </Box>
            </Box>
            <Modal.Stack>
                <Modal {...stack.register('preview-file')} size="auto" transitionProps={{ duration: 200, transition: 'fade' }} centered title={previewRecord?.name} fullScreen={isMobile}>
                    <Image src={previewRecord?.url} mah="75vh" />
                </Modal>
                <Modal {...stack.register('export-selection')} size="auto" transitionProps={{ duration: 200, transition: 'fade' }} centered title="Export Option">
                    <Stack>
                        <Select
                            data={[ExportOption.SAP, ExportOption.CSV]}
                            allowDeselect={false}
                            checkIconPosition="right"
                            leftSectionPointerEvents="none"
                            leftSection={<IconShare2 style={{ width: rem(16) }} />}
                            defaultValue={ExportOption.SAP}
                            value={exportOption}
                            onChange={setExportOption}
                        />
                        <Button
                            fullWidth
                            onClick={() => {
                                if (exportOption && isExportOption(exportOption)) {
                                    exportRecord(selectedRecords, exportOption);
                                    stack.close('export-selection');
                                }
                            }}
                        >
                            Confirm
                        </Button>
                    </Stack>
                </Modal>
            </Modal.Stack>
        </Box>
    );
}
